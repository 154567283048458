import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API } from '../../App';
import Fancybox from '../../Component/FancyBox';
import Layout from '../../layout/Layout';
import Cookie  from 'js-cookie'

const ImagesView = () => {
    const { id } = useParams()
    const [category, setCategory] = useState([])
    const [loading, Setloading] = useState(true)
    const [user, setuser] = useState({
        Name: "",
        status: "",
        Ispremium: "",
        thumbImg: "",
        media: ""
    })
    const [LoadData, setLoadData] = useState([])
    let navigate = useNavigate();

    const loadData = async (_id) => {
        const token = Cookie.get('pcjwt')
        const res = await API.post(`/imagesgetdata`)
        console.log(res)
        setLoadData(res.data.data)
        const viewData = await API.post(`/view_images/${_id}`)
        setCategory(viewData.data.data._id)
        setuser({
            Name: viewData.data.data.Name,
            status: viewData.data.data.status,
            Ispremium: viewData.data.data.Ispremium,
            type:viewData.data.data.type,
            thumbImg:viewData.data.data.thumbImg,
            media:viewData.data.data.media
           
        })
        Setloading(false)

    }

    const tokenAuth = async () => {
        const token = Cookie.get('pcjwt')
        const response = await API.post("/homePageApi",{}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }

    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }

    useEffect(() => {
        loadData(id)
        tokenAuth()
    }, [])


    return (
        <>
            <Layout sidebar={true}>
                <div div className="page-heading">
                {loading === true ? <div className='loader'></div> : ""}
                    <h3><Link to="/images" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link><span>View Images</span> - {user.Name}</h3>
                    <Row className="align-items-center">
                        <Col md="auto" xs={6}>
                            <Form.Select className="my-1" onChange={(e) => { getCategory(e) }}>
                                {
                                    LoadData.map((dataVal, index) => {
                                        return (
                                            <option key={index} value={dataVal._id} selected={user.Name === dataVal.Name ? true : false}>{dataVal.Name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col md="auto" xs={6}>
                            <Link to={`/images/edit/${category} `}>
                                <Button variant="primary" value="edit">Edit</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <div className='page-content'>
                    {/* {loading === true ? <div className='loader table-loader'></div> : ""} */}
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'> Name</p> <span>{user.Name}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p> <span>{user.status === "true" ? "On" : "Off"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is Premium</p> <span>{user.Ispremium === "true" ? "Yes" : "No"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Thumb Image</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.thumbImg}>
                                                <img src={user.thumbImg} className='hv-40' alt="thumb images" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Main Image</p>
                                        <span>
                                        <Fancybox>
                                            <a data-fancybox="gallery" href={user.media}>
                                                <img src={user.media} className='hv-40' alt="main images" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default ImagesView;