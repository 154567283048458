import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useNavigate, Link } from 'react-router-dom';
import { API } from '../App';
import Cookie from 'js-cookie'

const Home = () => {

  const navigate = useNavigate()

  const [loading, Setloading] = useState(true)
  const [total, settotal] = useState(0)


  const tokenAuth = async () => {
    const token = Cookie.get('pcjwt')
    const response = await API.post("/homePageApi", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }


  const quotestoal = async () => {
    const res = await API.post("/total")
    settotal(res.data.data)
    Setloading(false)
  }

  useEffect(() => {
    quotestoal()
    tokenAuth()
  })
  return (
    <Layout sidebar={true}>
      <>
        {loading === true ? <div className='loader table-loader'></div> : ""}
        <div className="vv-dashboard">
          <Row>
            <Col xxl={3} xl={4} md={6}>
              <Card>
                <Card.Body>
                  <Link to="/images">
                    <div className="counter orange">
                      <div className="counter-media">
                        <i class='bx bx-images'></i>
                      </div>
                      <div className="counter-content">
                        <h3>{total}</h3>
                        <p>Images</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </Layout>
  )
}

export default Home;



