import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from '../../layout/Layout';
import { API } from "../../App"
import { toast } from 'react-toastify';
import Switch from "react-switch";
import $ from 'jquery'
import Cookie from 'js-cookie'

const ImagesAdd = () => {
    const [status, setstatus] = useState("false")
    const [premium, setpremium] = useState("false")
    const [errors, setErrors] = useState({})
    const [validate, setValidate] = useState(false)
    const [IsSelected, setIsSelected] = useState(false);
    const [selectedfile, setSelectedfile] = useState(null);
    const [selectedFile, setSelectedFile] = useState();
    let navigate = useNavigate();

    const [user, setuser] = useState({
        Name: "",
        type: "",

    })
    const { Name, type } = user

    const onInputchange = (e) => {
        console.log(e.target.value)
        setuser({ ...user, [e.target.name]: e.target.value })
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
        if (e.target.value === "video") {
            $('#label').text('Video')
        } else {
            $('#label').text('Main Images')
        }

    }

    const tokenAuth = async () => {
        const token = Cookie.get('pcjwt')
        const response = await API.post("/homePageApi", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }



    const handeelsubmission = async () => {

        if (user.type === "" || !user.type) {
            setValidate(true)
        }
        else if (user.Name === "" || !user.Name) {
            setValidate(true)
        }
        else if (selectedfile === "" || !selectedfile) {
            setValidate(true)
        }
        else if (selectedFile === "" || !selectedFile) {
            setValidate(true)
        }
        else {

            const formData = new FormData();
            formData.append('Name', Name)
            formData.append('type', type)
            formData.append('status', status)
            formData.append("Ispremium", premium)
            formData.append("file", selectedfile)
            formData.append("filed", selectedFile)
            const token = Cookie.get('pcjwt')
            const res = await API.post("/create_Images", formData, { headers: { "Authorization": `Bearer ${token}` } })
            if (res.data.status === true) {
                navigate("/images")
                toast.success("Add data sucessfully", { position: "bottom-right", autoClose: 500 })
            } else {
                if (res.data.response_code === 429) {
                    navigate("/")
                } else {
                    toast.error("something wait worng.", { position: "bottom-right", autoClose: 500 })
                }
            }


        }
    }
    useEffect(() => {
        tokenAuth()
    }, [])

    const changeHandler = (event) => {
        console.log(event.target.files[0])
        setSelectedfile(event.target.files[0]);
        setIsSelected(true);

    }

    const changeHandlers = (event) => {
        console.log(event.target.files[0])
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);

    }


    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Create Images</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item href='/home'>
                        <i className='bx bx-home-alt me-2 fs-5' ></i> Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href='/images'>
                        images
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create Images</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col md={4} xs={12}>
                                            <Form.Label htmlFor="media_type" > Type</Form.Label>
                                            <Form.Select className='my-1' name='type' onChange={onInputchange} required >
                                                <option value="">Select Is type</option>
                                                <option value="image">Image</option>
                                                <option value="video">Video</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Enter Type
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label htmlFor="media_type" > Name</Form.Label>
                                                <Form.Control type="text" className="my-2" name='Name' placeholder='Enter Name' onChange={onInputchange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Enter Name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Row>
                                            <Col lg={4} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="cate-thumb" >Thumb Images</Form.Label>
                                                    <div className='side-img'>
                                                        <Form.Control type="file" id='cate-thumb' name='file' className="my-2 pe-5" onChange={changeHandler} required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Enter Thumb Images
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="cate-bg" id='label'>Main Images</Form.Label>
                                                    <div className='side-img'>
                                                        <Form.Control type="file" name='filed' id='Print' className="my-2 pe-5" onChange={changeHandlers} required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Enter Main Images
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Col md={2}>
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                                            <Switch
                                                onChange={(checked) => { if (checked === true) { setpremium("true"); console.log(checked, "s-on") } else { setpremium("false"); console.log(checked, "s-off") } }}
                                                checked={premium === "true" ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>NO</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>YES</div>
                                                }
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                            <Switch
                                                onChange={(checked) => { if (checked === true) { setstatus("true"); console.log(checked, "s-on") } else { setstatus("false"); console.log(checked, "s-off") } }}
                                                checked={status === "true" ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>ON</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={handeelsubmission}>Save</Button>
                                    <Link to="/images">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default ImagesAdd;