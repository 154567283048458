import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Images from './pages/Caller-Images/Images';
import ImagesAdd from './pages/Caller-Images/ImagesAdd';
import ImagesEdit from './pages/Caller-Images/ImagesEdit';
import Error from './pages/Error/Error'
import './App.css';
import './utilities.css';
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ImagesView from './pages/Caller-Images/ImagesView';
export const API = axios.create({ baseURL: process.env.REACT_APP_URL })



function App() {
  return (
    <>
      <ToastContainer></ToastContainer>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/images" element={<Images />} />
          <Route path="/images/add" element={<ImagesAdd />} />
          <Route path="/images/edit/:id" element={<ImagesEdit />} />
          <Route path='/images/view/:id' element={<ImagesView />} />


          <Route path='*' element={<Error />} />

          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}


export default App;
