import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import { toast } from 'react-toastify';
import { API } from "../../App"
import Switch from "react-switch";
import $ from 'jquery'
import Cookie from 'js-cookie'
import Fancybox from '../../Component/FancyBox';

const ImagesEdit = () => {
    const { id } = useParams()
    let navigate = useNavigate()
    const [LoadData, setLoadData] = useState([])
    const [errors, setErrors] = useState({})
    const [validate, setValidate] = useState(false)
    const [loading, Setloading] = useState(true)
    const [IsSelected, setIsSelected] = useState(false);
    const [selectedfile, setSelectedfile] = useState(null);
    const [selectedFile, setSelectedFile] = useState();
    const [user, setuser] = useState({
        type: "",
        Name: "",
        media: "",
        thumbImg: "",
        Ispremium: "",
        status: ""
    })
    const { Name } = user
    const onInputchange = (e) => {
        setuser({ ...user, [e.target.name]: e.target.value })
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })

    }

    const tokenAuth = async () => {
        const token = Cookie.get('pcjwt')
        const response = await API.post("/homePageApi", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }


    const handeelsubmissiom = async () => {
        if (user.Name === "" || !user.Name) {
            setValidate(true)
        }
        else {
            const formdata = new FormData()
            formdata.append('Name', Name)
            formdata.append('status', user.status)
            formdata.append("Ispremium", user.Ispremium)
            formdata.append("file", selectedfile)
            formdata.append("filed", selectedFile)
            const token = Cookie.get('pcjwt')
            const res = await API.post(`/update/${id}`, formdata, { headers: { "Authorization": `Bearer ${token}` } })
            if (res.status === 200) {
                navigate(`/images/view/${id}`)
                toast.success("Update data sucessfully", { position: "bottom-right", autoClose: 500 })
            } else {
                if (res.data.response_code === 429) {
                    navigate("/")
                } else {
                    toast.error("something wait wrong.", { position: "bottom-right", autoClose: 500 })
                }

            }

        }
    }
    const loadData = async () => {
        const res = await API.post(`/view_images/${id}`)

        setuser({
            type: res.data.data.type,
            Name: res.data.data.Name,
            status: res.data.data.status,
            Ispremium: res.data.data.Ispremium,
            thumbImg: res.data.data.thumbImg,
            media: res.data.data.media
        })
        Setloading(false)
        setLoadData(res.data.data)


    }
    useEffect(() => {
        tokenAuth()
        loadData()
    }, [])


    const statuschanges = (e) => {
        const result = (e === true) ? "true" : "false"
        setuser({
            ...user, ['status']: result
        })
    }

    const statuschange = (e) => {
        const result = (e === true) ? "true" : "false"
        setuser({
            ...user, ['Ispremium']: result
        })
    }

    const changeHandler = (event) => {
        console.log(event.target.files[0])
        setSelectedfile(event.target.files[0]);
        setIsSelected(true);

    }

    const changeHandlers = (event) => {
        console.log(event.target.files[0])
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);

    }

    if (user.type === "video") {
        $('#label').text('Video')
    } else {
        $('#label').text('Main Images')
    }
    return (

        <Layout sidebar={true}>
            <div className="page-heading">
                {loading === true ? <div className='loader'></div> : ""}
                <h3>Edit Images</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item href='/home'>
                        <i className='bx bx-home-alt me-2 fs-5' ></i> Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href='/images'>
                        Images
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Images</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col md={4} xs={12}>
                                            <Form.Label htmlFor="media_type" > Type</Form.Label>
                                            <Form.Select className='my-1' name='type' isInvalid={!!errors.name} disabled >
                                                <option value="">Select Is type</option>
                                                <option value="image" selected={user.type === "image" ? true : false}>Image</option>
                                                <option value="video" selected={user.type === "video" ? true : false}>Video</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label htmlFor="media_type" > Name</Form.Label>
                                                <Form.Control type="text" className="my-2" name='Name' value={user.Name} placeholder='Enter Category Name' onChange={onInputchange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Enter Name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Row>
                                            <Col lg={4} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="cate-thumb" >Thumb Images</Form.Label>
                                                    <div className='side-img'>
                                                        <Form.Control type="file" id='cate-thumb' name='file' className="my-2 pe-5" onChange={changeHandler} />
                                                        <Fancybox>
                                                            <a data-fancybox="gallery" href={user.thumbImg}>
                                                                <img src={user.thumbImg} className='hv-40 bg-light' alt="" />
                                                            </a>
                                                        </Fancybox>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="cate-bg" id='label' >Main Images</Form.Label>
                                                    <div className='side-img'>
                                                        <Form.Control type="file" name='filed' id='Print' className="my-2 pe-5" onChange={changeHandlers} />
                                                        <Fancybox>
                                                            <a data-fancybox="gallery" href={user.media}>
                                                                <img src={user.media} className='hv-40 bg-light' alt="" />
                                                            </a>
                                                        </Fancybox>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Col md={2}>
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                                            <Switch
                                                onChange={statuschange}
                                                checked={user.Ispremium === "true" ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>NO</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>YES</div>
                                                }
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                            <Switch
                                                onChange={statuschanges}
                                                checked={user.status === "true" ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>ON</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={handeelsubmissiom}>Save</Button>
                                    <Link to="/images">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default ImagesEdit;