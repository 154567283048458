import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { API } from '../../App'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import Pagination from 'rc-pagination';
import Fancybox from '../../Component/FancyBox';
import Switch from "react-switch";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Cookie from 'js-cookie'


const Images = () => {
  const [data, setdata] = useState([])
  const [loading, Setloading] = useState(true)
  const navigate = useNavigate()
  const [iconcoror, Seticoncoror] = useState("catagory_up")
  const [dataLength, setdataLength] = useState(1)
  const [search, setSearch] = useState({
    name: "",
    status: "",
    premium: ""
  })


  const tokenAuth = async () => {
    const token = Cookie.get('pcjwt')
    const response = await API.post("/homePageApi", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }


  const getdata = async (sortdata) => {
    const token = Cookie.get('pcjwt')
    const res = await API.post("/imagesgetdata")
    console.log(res);
    if (res.data.status === false) {
      if (res.data.response_code === 429) {
        navigate("/")
      }
    } else {
      setdata(res.data.data)
      Setloading(false)
    }

  }

  useEffect(() => {
    tokenAuth()
    getdata()
  }, [])

  const deleteUser = async (_id) => {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger me-3'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const dele = await API.post(`/delete/${_id}`)
        getdata(sort)
        if (current !== 1) {
          setCurrent(current - 1)
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {

      }
    })

    console.log(_id);

  }


  const change = async (id, e, name, ind) => {

    var Status
    Status = (e === true) ? true : false
    toast.success("Update Data sucessfully", { position: "bottom-right", autoClose: 500 })
    if (name === "status") {

      const data1 = data
      data1[ind].status = Status
      setdata(data1)
    } else {
      const data1 = data
      data1[ind].Ispremium = Status
      setdata(data1)
    }
    const Form = new FormData()
    Form.append('id', id)
    Form.append('name', name)
    Form.append('Status', Status)

    const res = await API.post("/Imagestatus", Form)
    console.log(res)
    getdata()


  }



  const SerachData = async (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value })
    const res = await API.post('/SerchData', { Name: (e.target.name === "Name") ? e.target.value : search.Name, status: (e.target.name === "status") ? e.target.value : search.status, Ispremium: (e.target.name === "Ispremium") ? e.target.value : search.Ispremium, type: (e.target.name === "type") ? e.target.value : search.type })
    setdata(res.data.data)
    if (res.data.data.length === 0) {
      setdataLength(0)
    } else {
      setdataLength(1)
    }

  }

  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [sort, setsort] = useState(1)
  const [order, setOrder] = useState("ASC")
  // const [ischeaked, setcheakd] = useState([])

  const getData = (current, pageSize) => {
    return data.slice((current - 1) * pageSize, current * pageSize);
  };

  const PerPageChange = (value) => {
    console.log(value)
    setSize(value);
    console.log(size)
    const newPerPage = Math.ceil(data.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  }

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
  }


  const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className='paggination-btn'>Previous</button>;
    }
    if (type === 'next') {
      return <button className='paggination-btn'>Next</button>;
    }
    return originalElement;
  }


  const sorting = (col, order, e) => {
    Seticoncoror(e.target.id)
    if (order === "ASC") {
      if (col === 'status' || col === 'Ispremium') {
        const sorted = [...data].sort((a, b) =>
          a[col] === b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setdata(sorted)
        }
      } else {
        const sorted = [...data].sort((a, b) =>
          a[col] > b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setdata(sorted)
        }
      }
    }
    if (order === "DSC") {
      if (col === 'status' || col === 'Ispremium') {
        const sorted = [...data].sort((a, b) =>
          a[col] === b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setdata(sorted)
        }
      } else {
        const sorted = [...data].sort((a, b) =>
          a[col] < b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setdata(sorted)
        }
      }
    }
  }

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setdata(tempData);

    var order = [];
    var x = (tempData).map(function (index, element) {
      order.push({
        id: index._id,
        position: element + 1,
      });
    });
    sortCategory(order);

  };
  const sortCategory = async (data) => {
    console.log(data, "datatata")
    const token = Cookie.get('pcjwt')
    const results = await API.post('/channelSort', data, { headers: { "Authorization": `Bearer ${token}` } })
    console.log(results)
    if (results.data.response_code === 200) {
      toast.success("Update Data sucessfully", { position: "bottom-right", autoClose: 500 })
    }
  }


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Images</h3>
        <Row className="align-items-center">
          <Col md="auto" xs={12}>
            <Form.Control type="text" name="Name" id="" placeholder="Search Name" onChange={SerachData} className="my-1" />
          </Col>
          <Col md="auto" xs={12}>
            <Form.Select className='my-1' name='type' onChange={SerachData}>
              <option value="">Media Type</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
            </Form.Select>
          </Col>
          <Col md="auto" xs={12}>
            <Form.Select className='my-1' name='Ispremium' onChange={SerachData}>
              <option value="">Select Premium</option>
              <option value="true">YES</option>
              <option value="false">NO</option>
            </Form.Select>
          </Col>
          <Col md="auto" xs={12}>
            <Form.Select className='my-1' name='status' onChange={SerachData} >
              <option value="">Select Status</option>
              <option value="true">ON</option>
              <option value="false">OFF</option>
            </Form.Select>
          </Col>
          <Col md="auto" xs={6}>
            <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
              {
                [10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))
              }
            </Form.Select>
          </Col>
          <Col md="auto" xs={6}>
            <Link to="/images/add">
              <Button variant="primary" className='my-1' value="create">Add New</Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <DragDropContext onDragEnd={handleDragEnd}>
                  {loading === true ? <div className='loader table-loader'></div> : ""}
                  <Table bordered responsive id="myTable">
                    <thead>
                      <tr>
                        <th width="5%" style={{ textAlign: "center" }}>
                          No
                        </th>

                        <th width="29%">
                          <div className='table-sort-filter'>
                            Name
                            {data.length > 1 ?
                              <span className='table-sort'>
                                <div className={`sort-down ${iconcoror === "catagory_down" ? "active" : ""}`} id="catagory_down" onClick={(e) => { sorting('Name', "DSC", e) }} ></div>
                                <div className={`sort-up ${iconcoror === "catagory_up" ? "active" : ""}`} id="catagory_up" onClick={(e) => { sorting('Name', "ASC", e) }} ></div>
                              </span>
                              : ""}
                          </div>
                        </th>
                        <th width="10%">

                          Thumb Image
                        </th>
                        <th width="10%">
                          Main Image | Video
                        </th>

                        <th width="12%">
                          <div className='table-sort-filter justify-content-center'>
                            Is Premium
                            {data.length > 1 ?
                              <span className='table-sort'>
                                <div className={`sort-down ${iconcoror === "premium_down" ? "active" : ""}`} id="premium_down" onClick={(e) => { sorting('Ispremium', "DSC", e) }} ></div>
                                <div className={`sort-up ${iconcoror === "premium_up" ? "active" : ""}`} id="premium_up" onClick={(e) => { sorting('Ispremium', "ASC", e) }} ></div>
                              </span>
                              : ""}
                          </div>
                        </th>

                        <th width="12%">
                          <div className='table-sort-filter justify-content-center'>
                            Status
                            {data.length > 1 ?
                              <span className='table-sort'>
                                <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "DSC", e) }} ></div>
                                <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "ASC", e) }} ></div>
                              </span>
                              : ""}
                          </div>
                        </th>

                        <th width="12%" className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <Droppable droppableId="droppable-1">
                      {(provider) => (
                        <tbody ref={provider.innerRef}
                          {...provider.droppableProps}>
                          {
                            getData(current,size).map((user, ind) => (
                              <Draggable
                                key={user._id}
                                draggableId={user._id}
                                index={ind}
                              >
                                {(provider) => (
                                  <tr key={ind} {...provider.draggableProps} ref={provider.innerRef}>
                                    <td className='text-center' {...provider.dragHandleProps}>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                    <td {...provider.dragHandleProps}>{user.Name}</td>
                                    <td className='text-center' {...provider.dragHandleProps}>
                                      <Fancybox>
                                        <a data-fancybox="gallery" href={user.thumbImg}>
                                          <img src={user.thumbImg} className='hv-40' alt="Thumimage" />
                                        </a>
                                      </Fancybox>
                                    </td>
                                    <td className='text-center' {...provider.dragHandleProps}>
                                      <Fancybox>
                                        <a data-fancybox="gallery" href={user.media}>
                                          <img src={user.media} className='hv-40' alt="Mainimage" />
                                        </a>
                                      </Fancybox>
                                    </td>
                                    <td className='text-center'{...provider.dragHandleProps}>
                                      <Switch
                                        onChange={(e) => { change(user._id, e, "Ispremium", ind) }}
                                        checked={user.Ispremium === "true" ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={
                                          <div className='react-switch-off'>NO</div>
                                        }
                                        checkedIcon={
                                          <div className='react-switch-on'>YES</div>
                                        }
                                      />
                                    </td>
                                    <td className='text-center' {...provider.dragHandleProps}>
                                      <Switch
                                        onChange={(e) => { change(user._id, e, "status", ind) }}
                                        checked={user.status === "true" ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={
                                          <div className='react-switch-off'>OFF</div>
                                        }
                                        checkedIcon={
                                          <div className='react-switch-on'>ON</div>
                                        }
                                      />
                                    </td>
                                    <td className='text-center'>
                                      <Link to={`/images/view/${user._id}`}>
                                        <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                      </Link>
                                      <Link to={"/images"}>
                                        <Button variant="outline-danger" size="sm" className="btn-icon"><i className='bx bx-trash-alt' onClick={() => deleteUser(user._id)} ></i></Button>
                                      </Link>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))
                          }
                          {provider.placeholder}
                          {
                            dataLength === 0 ? <tr>
                              <td colSpan="100%" className="p-0">
                                <div className='no-found'>
                                  <img src="../../not-found/language.svg" />
                                  <p>No Found Images List</p>
                                </div>
                              </td>
                            </tr> : ""
                          }
                        </tbody>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
                {(data.length > size) ?
                  <div className="pagination-custom">
                    <Pagination
                      className="pagination-data"
                      onChange={PaginationChange}
                      total={data.length}
                      current={current}
                      pageSize={size}
                      showSizeChanger={false}
                      itemRender={PrevNextArrow}
                      onShowSizeChange={PerPageChange}
                      showTitle={false}
                    />
                  </div> : ""}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default Images;